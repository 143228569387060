import React from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import Image from "next/image";
const Layout = dynamic(() => import("../components/common/Layout/Layout"));
import error from "../../public/assets/images/404-error.svg";

const PageNotFound = () => {
  return (
    <>
      <Layout>
        <main className="main-wrapper error-page-middle wrapper-top-bg">
          <article className="wrapper-inner">
            <section className="error-content-section">
              <div className="container">
                <div className="row error-content-row">
                  <div className="col-md-6">
                    <div className="error-content">
                      <div className="error-page-title">
                        <h2>Opps...!</h2>
                        <h1>
                          Page Not <span>Found</span>
                        </h1>
                      </div>
                      <div className="error-page-msg">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry Lorem Ipsum has been the
                          industry's.
                        </p>
                      </div>
                      <div className="back-home-btn">
                        <Link className="btn btn-primary" href="/">
                          Back to Home
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="error-page-img">
                      <Image
                        src={error}
                        height={500}
                        width={500}
                        alt="Page Not Found"
                        loading="eager"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
      </Layout>
    </>
  );
};

export default PageNotFound;
